
import axios from "axios";

const domain = process.env.VUE_APP_DOMAIN
axios.defaults.baseURL = domain;

export default {
    create(payload) {
        const form = new FormData();
        Object.keys(payload).forEach((key) => {
            if(payload[key]){
                if (key === "image" || key === "video" || key === "audio") {
                    form.append(key, payload[key]);
                } else {
                    form.append(key, payload[key]);
                }
            }else{
                form.append(key, "");
            }
        });
        return axios.post("v1_genesis/v1/taxonomies/create", form);
    },
    update(id, payload) {
        const form = new FormData();
        Object.keys(payload).forEach((key) => {
            if(payload[key]){
                if (key === "image" || key === "video" || key === "audio") {
                    form.append(key, payload[key]);
                } else {
                    form.append(key, payload[key]);
                }
            }else{
                form.append(key, "");
            }
        });
        return axios.put(`v1_genesis/v1/taxonomies/update/${id}`, form);
    },
    remove(id) {
        return axios.delete(`v1_genesis/v1/taxonomies/remove/${id}`);
    },
    delete(id) {
        return axios.delete(`v1_genesis/v1/taxonomies/delete/${id}`);
    },
    get(id) {
        return axios.get(`v1_genesis/v1/taxonomies/${id}`);
    },
    paginate(query) {
        return axios.get(`v1_genesis/v1/taxonomies/paginate`, { params: query });
    },
    all() {
        return axios.get(`v1_genesis/v1/taxonomies/`);
    }
}
