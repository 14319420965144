import UsersMenu from '@/modules/users/users-menu';
import TemplatesMenu from '@/modules/templates/templates-menu';
import SchoolsMenu from '@/modules/schools/schools-menu';
import BooksMenu from '@/modules/books/books-menu';
import BooksCategoriesMenu from '@/modules/books-categories/books-categories-menu';
import BooksPagesMenu from '@/modules/books-pages/books-pages-menu';
import BooksValuesMenu from '@/modules/books-values/books-values-menu';
import BudgetsAddressMenu from '@/modules/budgets-address/budgets-address-menu';
import BudgetsMenu from '@/modules/budgets/budgets-menu';
import BudgetsBooksMenu from '@/modules/budgets-books/budgets-books-menu';
import BudgetsCommentsMenu from '@/modules/budgets-comments/budgets-comments-menu';
import BudgetsDevolutionsMenu from '@/modules/budgets-devolutions/budgets-devolutions-menu';
import BudgetsDevolutionsBooksMenu from '@/modules/budgets-devolutions-books/budgets-devolutions-books-menu';
import BudgetsInvoicesMenu from '@/modules/budgets-invoices/budgets-invoices-menu';
import BudgetsInvoicesSlipsMenu from '@/modules/budgets-invoices-slips/budgets-invoices-slips-menu';
import BudgetsStatusMenu from '@/modules/budgets-status/budgets-status-menu';
import BudgetsStepsMenu from '@/modules/budgets-steps/budgets-steps-menu';
import BudgetsStepsNextMenu from '@/modules/budgets-steps-next/budgets-steps-next-menu';
import ContractsMenu from '@/modules/contracts/contracts-menu';
import ContractsAttachmentsMenu from '@/modules/contracts-attachments/contracts-attachments-menu';
import ContractsCommentsMenu from '@/modules/contracts-comments/contracts-comments-menu';
import ContractsFieldsMenu from '@/modules/contracts-fields/contracts-fields-menu';
import ContractsSubscriptionsMenu from '@/modules/contracts-subscriptions/contracts-subscriptions-menu';
import PeoplesMenu from '@/modules/peoples/peoples-menu';
import PeoplesRacesMenu from '@/modules/peoples-races/peoples-races-menu';
import PeoplesTypesMenu from '@/modules/peoples-types/peoples-types-menu';
import SchoolsContractsMenu from '@/modules/schools-contracts/schools-contracts-menu';
import SchoolsContractsFieldsMenu from '@/modules/schools-contracts-fields/schools-contracts-fields-menu';
import SchoolsInfosMenu from '@/modules/schools-infos/schools-infos-menu';
import SchoolsTypesMenu from '@/modules/schools-types/schools-types-menu';
import coreFormsMenu from "@/modules/core-forms/core-forms-menu";
import TaxonomiesTypesMenu from '@/modules/taxonomies-types/taxonomies-types-menu';
// import TaxonomiesMenu from '@/modules/taxonomies/taxonomies-menu';
import PostsTypesMenu from '@/modules/posts-types/posts-types-menu';
import PostsConfigsMenu from '@/modules/posts-configs/posts-configs-menu';
import PostsMenu from '@/modules/posts/posts-menu';
import TaxonomiesStore from "@/modules/taxonomies/taxonomies-store";
import TaxonomiesTypesStore from "@/modules/taxonomies-types/taxonomies-types-store";
import PostsTypesStore from "@/modules/posts-types/posts-types-store";

const conteudos = []



export default [
    {
        id: 'livros',
        label: 'Livros e conteúdo',
        icon: 'ri-pencil-ruler-2-line',
        subItems: [
            BooksMenu,
            BooksCategoriesMenu,
            BooksPagesMenu,
            BooksValuesMenu,
            // PostsMenu,
            // TaxonomiesMenu,
            PostsConfigsMenu,
            PostsTypesMenu,
            TaxonomiesTypesMenu,

            // PostsTaxonomiesMenu,
        ]
    },
    {
        id: 'Pedidos',
        label: 'Pedidos',
        icon: 'ri-pencil-ruler-2-line',
        subItems: [
            BudgetsMenu,
            BudgetsAddressMenu,
            BudgetsBooksMenu,
            BudgetsCommentsMenu,
            BudgetsDevolutionsMenu,
            BudgetsDevolutionsBooksMenu,
            BudgetsInvoicesMenu,
            BudgetsInvoicesSlipsMenu,
            BudgetsStatusMenu,
            BudgetsStepsMenu,
            BudgetsStepsNextMenu,
        ]
    },
    {
        id: 'Contratos',
        label: 'Contratos',
        icon: 'ri-pencil-ruler-2-line',
        subItems: [
            ContractsMenu,
            ContractsFieldsMenu,
            SchoolsContractsMenu,
            ContractsAttachmentsMenu,
            ContractsCommentsMenu,
            ContractsSubscriptionsMenu,
            // SchoolsContractsFieldsMenu,
        ]
    },
    {
        id: 'Pessoas',
        label: 'Pessoas',
        icon: 'ri-pencil-ruler-2-line',
        subItems: [
            // UsersMenu,
            PeoplesMenu,
            PeoplesRacesMenu,
            PeoplesTypesMenu,
        ]
    },
    {
        id: 'Escolas',
        label: 'Escolas',
        icon: 'ri-pencil-ruler-2-line',
        subItems: [
            SchoolsMenu,
            // SchoolsInfosMenu,
            SchoolsTypesMenu,
        ]
    },
    {
        id: 'Formulários',
        label: 'Formulários',
        icon: 'ri-pencil-ruler-2-line',
        subItems: [
            coreFormsMenu,
        ]
    },
    {
        id: 'Configurações',
        label: 'Configurações',
        icon: 'ri-pencil-ruler-2-line',
        subItems: [
            TemplatesMenu,
        ]
    }
]
